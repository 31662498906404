.containerGraphs {
  width: 100%;
  overflow-y: scroll;
}

.Styetitle {
  color: #02274190;
  font-weight: bold;
  font-size: 25px;
  font-style: normal;
  top: 118;
  font: " Roboto";
  line-height: 140%;
  margin-left: 7%;
  margin-top: 2%;
}

.subcontainergraphics {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}
.subcontainergraphicsfive,
.subcontainergraphicstwo {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: 3%;
}

.subcontainergraphicsthree {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50%;
  margin-bottom: 5%;
}

.subcontainergraphicsfour {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.subcontainergraphicssix {
  display: none;
}
.multiselect {
  width: auto;
  // margin-left: 3%;
  margin-bottom: 5px;
  cursor: pointer;
  border-color: 2px solid rgb(228, 228, 228);
  margin-right: 10px;
  position: relative;
}

.multiselect2 {
  width: 130px;
  // margin-left: 3%;
  margin-bottom: 5px;
  cursor: pointer;
  border-color: 2px solid rgb(228, 228, 228);
  margin-right: 10px;
  position: relative;
}

.selectBox {
  position: relative;
}
.selectBox2 {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
  border: 2px solid #027CBB80;
  border-radius: 5px;
  height: 30px;
  padding: 3px 6px;
}

.selectBox2 select {
  width: 100%;
  font-weight: bold;
  border: 2px solid hsl(0, 0%, 87%);
  border-radius: 5px;
  color: #a09f9f;
  height: 30px;
  padding: 3px 6px;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.checkboxes {
  display: none;
  border: 1px #dadada solid;
  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
}

.checkboxes label {
  display: block;
}

.checkboxes label:hover {
  background-color: #1e90ff;
}

.containerCheckbox {
  background-color: white;
}

.selectBox {
  position: relative;
}

.inputCalendariorango {
  border: 2px solid #027cba;
  border-radius: 5px;
}

.noneinmobile {
  width: 300px;
}
@media (max-width: 991px) {
  .subcontainergraphics {
    flex-direction: column;
    align-items: center;
  }
  .subcontainergraphicsthree {
    flex-direction: column;
  }
  .subcontainergraphicsfour {
    flex-direction: column;
  }
  .subcontainergraphicsfive {
    display: none;
  }
  .subcontainergraphicssix {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-left: 3%;
  }
  .noneinmobile {
    display: none !important;
  }
}

@media (max-width: 1084px) {
  .subcontainergraphicstwo {
    flex-direction: column;
  }
  .subcontainergraphicsfive {
    flex-direction: column;
  }
  .subcontainergraphicsthree {
    width: 100%;
    height: auto;
  }
}

.formGeneral {
  z-index: 2;
  margin-bottom: 50px;
}

.formGeneral2 {
  z-index: 2;
  margin-bottom: 0px;
  align-self: flex-end;
  position: absolute;
  top: 11px;
  // right: 24px;
  right: 40px;
}
