.General_Container_Cards {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  @media screen and (max-width: 375px) {
  }
}
.Container_Card {
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: row-reverse;

    justify-content: space-between;

    width: 323px;
    height: 100px;
    border-radius: 8px;
    margin-bottom: 1px;
  }
}

.Container_Card_Grid {
  @media screen and (max-width: 375px) {
  }
}
// .Card_grid {
//   display: flex;
//   justifycontent: center;
//   alignitems: center;
//   flexdirection: column;
//   @media screen and (max-width: 375px) {
//     width: 150px;
//     height: 321px;
//     background-color: red;
//   }
// }
.Card {
  width: 100%;
  height: 260px;
  @media screen and (max-width: 375px) {
    width: 161.3px;
    height: 100px;
  }
}

.card_Image {
  height: 250px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 375px) {
    width: 161.3px !important;
    height: 100px !important;
    object-fit: contain !important;
    cursor: pointer;
  }
}

.Name_Category {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  font-weight: bold;
  font-size: 25px;
  @media screen and (max-width: 375px) {
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    align-items: center;
  }
}

.Border_Shadow_General_Item {
  max-width: 355;
  box-shadow: -2px 2px 14px -2px rgba(0, 0, 0, 0.58);
  height: auto;
}

.card_Image:hover {
  height: 300px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  @media screen and (max-width: 375px) {
    width: 161.3px;
    height: 100px;
    object-fit: contain;
  }
}

.container_pay_and_detail {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: "350px";
}
.add_button {
  background: #027cbb;
  width: 183px;
  height: 48px;
  color: white;

  font-weight: 750;
  margin-left: 5%;
  border: 1px solid #027cbb;
  border-radius: 2px;
  font-size: 14px;
}

.show_detail {
  color: #027cbb;

  margin-top: 20px;
  @media screen and (max-width: 375px) {
    font-size: 10px !important;
    text-decoration: underline;
    margin: 0px !important;
    margin-top: 3px !important;
  }
}

@media (max-width: 991px) {
  .container_pay_and_detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // width: 350px;
  }
  .add_button {
    background: #027cbb;
    width: 128px;
    height: 30px;
    color: white;
    margin: unset;
    font-weight: 400;
    margin-left: 5%;
    border: 1px solid #027cbb;
    border-radius: 20px;
    font-size: 11px;
  }
  .showMobile {
    display: none;
  }
}

.opacity {
  opacity: 0.3;
}
.displayNone {
  display: none;
}
