.tooltip {
  height: inherit;
}

.buttonCard {
  background: #027cbb;
  width: 40%;
  color: white;
  margin: unset;
  font-weight: 750;
  margin-left: 5%;
  border: 1px solid #027cbb;
  border-radius: 2px;
}

.showMobile {
  display: flex;
  margin: space-bewtween;
  box-sizing: border-box;
  width: 350px;
}

@media ((max-width: 1024px)) {
  .showMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .buttonCard {
    border-radius: 11px;
    width: 54%;
    font-weight: 600;
    padding: 1%;
    margin: 2%;
    font-size: larger;
  }
}
@media ((max-width: 426px)) {
  .buttonCard {
    width: 47%;
  }
}
@media ((max-width: 320px)) {
  .buttonCard {
    width: 38%;
    font-size: inherit;
  }
}
