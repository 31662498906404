body {
  font-family: Helvetica;
}
.filtersComponentInsideProduct {
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.back {
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    width: 20%;
  }
}

.categories_sub_nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  //background-color: green;
}
.categorias_menu_hamburguesa {
  background-color: #027cbb;
  color: white;
  // font-weight: bold;
  font-size: 16px;
  // display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  width: 250px;
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.close_left_menu {
  color: white;
  transform: scale(2);
  position: absolute;
  right: -20px;
  z-index: 3;
  cursor: pointer;
}
.categorias_menu_hamburguesa_sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 15px 10px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.categorias_menu_backdrop {
  display: flex;
  position: fixed;
  background-color: black;
  height: 150vh;
  width: 400vh;
}

.categorias_menu_lateral {
  width: 10px;
  position: fixed;
  padding: 10px 10px;
  min-width: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: black;
  font-size: 14px;
  height: 95vh;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  font-weight: 500;
  transition: all 0.3s ease-in-out 1s;
  // width: 300px;
  animation: pruebamov 0.3s 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  h5 {
    color: black;
  }
}

@keyframes pruebamov {
  100% {
    width: 300px;
    min-width: 160px;
  }
}
.container_items_categorias_menu {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 15px;
}
.items_categorias {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}
.menu_hamburguesa_item {
  margin-right: 15px;
  transform: scale(1.2);
}

.categorias_general_buscador {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px 30px;
  border: 2px solid rgb(209, 209, 209);
  font-size: 16px;
}
.categorias_general_buscador:focus {
  border: 2px solid #1295d6(5, 11, 198);
  outline: none !important;
}

.categories_sub_nav_busqueda {
  display: flex;
  // background-color: orange;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 410.16px;
  @media screen and (max-width: 991px) {
    height: 189.22px;
  }
}
// cuaditritos de buscar
.categories_sub_nav_busqueda_center {
  display: flex;
  flex-direction: column;
  // background-color: brown;
  padding: 15px 10px;
  width: 80%;
  align-items: flex-end;

  @media screen and (max-width: 991px) {
    width: 100%;
    background-color: white;
    display: none;
  }
}
.handleShowMobileMenu {
  //display: none;
  @media screen and (max-width: 991px) {
    height: 60px;
    width: 80px;
    color: white;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    font-weight: 700;
  }
}
.handleCont {
  display: none;
  @media screen and (max-width: 991px) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-left: 15px;
  }
}
.displayShow {
  display: flex !important;
}

.categories_sub_nav_busqueda_row {
  display: flex;
  flex-direction: row;
  //background-color: rgba(56, 43, 233, 0.807);
  width: 100%;
}

.container_dropdowns_vehiculo {
  background-color: #ffffff;
  padding: 0.8%;
  border-radius: 3px;
  border: 2px solid #3bb9f8;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 78.9px;
  justify-content: space-between;
}

.container_dropdowns_vehiculo_border {
  //background-color: orange;
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.container_dropdowns_vehiculo_border_small {
  //background-color: orange;
  display: flex;
  width: 17%;
  justify-content: center;
  align-items: center;
  border-left: 1px solid gray;
  padding: 8px 10px;
  @media screen and (max-width: 991px) {
    height: 40px;
    border-radius: 8px !important;
  }
}
/* Dropdown Button */
.dropbtn {
  background-color: #ffffff;
  color: #75787b !important;
  padding: 2% 10px;
  font-size: 18.6077px;
  //border: none;
  // border-left: 2px solid rgba(128, 128, 128, 0.724);

  height: 70.9px;
  width: 165.51px;
  border: #1295d6;
  @media screen and (max-width: 991px) {
    font-size: 14px;
    height: 20px;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  direction: row;
  margin-right: 5px;
  border-radius: 3px;

  input {
    background-color: #b4e4fcbc;
    border: 1px solid #b4e4fcbc;
    border-radius: 3px;
  }
  input:focus {
    border: 1px solid #027cbb;
    outline: none;
    color: black;
    font-weight: bold;
  }
}

.dropdown_Row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  // background-color: green;
  width: 100%;
  @media screen and (max-width: 991px) {
  }
}

.dropbtn_input {
  width: 100%;
  @media screen and (max-width: 991px) {
    height: 40px;
    border-radius: 8px !important;
  }
}

.searchIcon {
  position: absolute;
  right: 2px;
}
.btn_Share {
  display: flex;
  background-color: #027cbb;
  color: white;
  border: none;
  outline: none;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
  border-radius: 3px;
  cursor: pointer;
  height: 78.9px;
  width: 165.51px;
  font-weight: 400;
  font-size: 22.3292px;
  @media screen and (max-width: 991px) {
    width: 128px !important;
    height: 36px !important;
    font-size: 14px !important;
  }
}
.btnCont {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: flex-end;

  margin-bottom: 100px;
}
.btn_cancel {
  display: none;
  @media screen and (max-width: 991px) {
    width: 128px;
    height: 36px;
    font-size: 14px;
    display: flex;
    background-color: red;
    color: white;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
    border-radius: 20px;
    cursor: pointer;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
  display: none;
  position: absolute;
  background-color: #027cbb;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: white;
  font-size: 14px;
  max-height: 600px;
  overflow-y: auto;
}

.categories_title_etiqueta {
  border-top: 1px solid;
}

.arrow_Down {
  margin-left: 6px;
  color: #75787b;
  @media screen and (max-width: 991px) {
    width: 16px;
    height: 16px;
  }
}

/* Links inside the dropdown */
.dropdown_content a {
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown_content a:hover {
  background-color: #032e43c6;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown_content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #e0f0f8bc;
  border-radius: 3px;
}
.dropdown:hover .arrow_Down {
  transform: rotate(-180deg);
  transition: all 0.2s ease-in;
}

.categories_title {
  font-size: 36px;
  font-weight: bold;
  margin-top: 30px;
  // margin-bottom: -30px;
  color: rgb(0, 0, 0);
  @media screen and (max-width: 991px) {
    font-size: 20px;
    // margin-bottom: 30px;
  }
}
.categories_title_line {
  color: #027cbb;
  @media screen and (max-width: 991px) {
    width: 48px;
  }
}

.container_general_body {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 0 5%;
}

.categories_sub_nav_busqueda_single {
  // color:k green;
  text-decoration: underline;
  margin-bottom: 3px;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
}

.categories_sub_nav_busqueda_href {
  color: #022741;
  font-weight: 400;
  margin-right: 5px;
  font-size: 18px;
  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
}
.white {
  color: white;
}
.arrow_item {
  color: #fff;
}

.backdrop {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  color: #fff;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

/*hidde menu */
.display_none {
  display: none;
}

.categorias_menu_hamburguesa_open {
  background-color: #027cbb;
  color: white;
  // font-weight: bold;
  font-size: 16px;
  // display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  width: 300px;
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}
.categorias_menu_hamburguesa_sub_open {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 15px 10px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.container_applied_filters_a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.container_pagination {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  // padding: 20px 5%;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
  }
}

.container_applied_filters {
  width: 80%;
  display: flex;
  align-self: center;
  justify-content: flex-start;
  position: relative;
  align-self: center;
}

.container_filter_name {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3bb9f8;
  border-radius: 10px;
  padding: 2px 10px;
  margin: 0px 3px;
  position: relative;
  background-color: #ffffff;
}
.delete_applied_filter {
  cursor: pointer;
  color: black;
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: white;
  border: 1px solid #3bb9f8;
  border-radius: 50%;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: rgb(241, 140, 140);
    color: white;
    transform: scale(1.2);
    transition: all 0.2s ease-in;
  }
}

@media (max-width: 991px) {
  .categories_sub_nav {
    display: none;
  }
  .categories_sub_nav_busqueda_row {
    display: flex;
    flex-direction: column;
    //background-color: rgba(56, 43, 233, 0.807);
    width: 100%;
  }
  .container_dropdowns_vehiculo {
    // background-color: pink;
    padding: 0.8%;
    border-radius: 3px;
    border: 0px solid #3bb9f8;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  .container_dropdowns_vehiculo_border_small {
    //background-color: orange;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    padding: 8px 10px;
    border-radius: 20px;
    margin-top: 15px;
  }
  .dropdown_Row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    width: 100%;

    input {
      // background-color: #b4e4fcbc;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid gray;
      padding: 20px 8px;
      border-radius: 20px;
      color: black;
      font-size: 20px;
    }
    input:focus {
      border: 1px solid #027cbb;
      outline: none;
      color: black;
      font-weight: bold;
      padding: 20px 8px;
      font-size: 20px;
    }
  }

  .btn_Share {
    display: flex;
    background-color: #027cbb;
    color: white;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
    border-radius: 3px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 20px;
    height: 78.9px;
    width: 165.51px;
    align-self: center;
    margin-top: 100px;
  }
  .container_dropdowns_vehiculo_border {
    // background-color: orange;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .dropbtn {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 2% 10px;
    font-size: 16px;
    border: none;
    // border-left: 2px solid rgba(128, 128, 128, 0.724);
    width: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .searchIcon {
    display: none;
  }
  .dropdown {
    width: 100%;
  }
  .dropdown_content {
    display: none;
    position: absolute;
    background-color: #027cbb;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    color: white;
    font-size: 14px;
    width: 100%;
  }

  .container_filter_name {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3bb9f8;
    border-radius: 20px;
    padding: 6px 30px;
    margin: 0px 3px;
    position: relative;
    font-size: 18px;
  }

  .dropdown_content a {
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    font-size: 20px;
  }
  .container_general_body {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px 20px;
  }
}

@media (max-width: 420px) {
  .container_general_body {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 0px;
  }
}

.back_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  outline: none;
  color: #027cbb;
  font-size: 22px;
  font-weight: 600;
  font-family: "Helvetica Neue", "Helvetica";
  width: fit-content;
}
