.mainDashboard {
  width: 100%;

  height: 90vh;
  background-color: #d4e1ec;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .tableMobile {
    @media only screen and (max-width: 768px) {
      display: flex;

      align-items: center;
      text-align: right;
      width: 100%;
      padding: 48px 15px 0px 15px;

      th {
        display: none;
      }
      td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        padding: 10px 10px 10px 0px;
      }
      td::before {
        content: attr(label);
        width: 50%;
        font-weight: bold;
        display: inline-block;
        text-align: left;
        padding: 0px 10px 10px 10px;
      }
    }
    width: 100%;
    padding: 100;
  }
  .tableContainerMobile {
    @media only screen and (max-width: 768px) {
      span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding-left: 11px;
        margin: 0px;
        text-align: left;
      }
      .sticky {
        text-align: left;
        padding: 10px;
      }
    }
  }
  .bottonMobile {
    @media only screen and (max-width: 768px) {
      display: flex;

      align-items: center;
      text-align: center;
      width: 100%;
      .col-lg-2 {
        padding: 0px;
        padding-top: 3px;
        justify-content: center;
        align-items: center;
        .btn-dark {
          width: 91%;
          height: 80%;
          padding: 2px;
          font-size: 18px;
        }
      }
      .btn {
        width: 80%;
        margin: 5px 0px 5px 0px;
        padding: 0px;
        font-size: 20px;
      }
    }
  }
  .filter {
    display: flex;
    justify-content: left;
    margin-top: 67%;
    margin-bottom: 7%;
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
      margin-top: 77%;
      margin-bottom: 7%;
    }
    @media (max-width: 413px) {
      margin-top: 5%;
    }
  }
  .chartContainer {
    @media only screen and (max-width: 768px) {
      width: 98%;
    }
    width: 85%;
    margin: 0 0 0 auto;
    padding: 20px;
    overflow-y: scroll;
  }
  .searchContainer {
    width: 100%;
    height: 12%;
    padding: 10px;
    .searchBar {
      height: 100%;
      // background-color: red;
      align-items: center;
      .inputBar {
        width: 60%;
        height: 100%;
        margin-left: auto;
        border: 2px solid #027cba;
        outline: 0;
        font-size: 16px;
        padding-left: 15px;
      }
      button {
        height: 100%;
        width: 150px;
        margin-right: auto;
        background-color: #027cba;
        border: 2px solid #027cba;
        font-size: 18px;
        font-weight: 500;
        border-radius: 0%;
      }
    }
  }

  .containerBoxes {
    height: 25%;
    width: 100%;
    background-color: #d4e1ec;
    margin-bottom: 10px;

    .leftBox {
      height: 100%;
      width: 49%;
      background-color: red;
      font-size: 18px;
      font-weight: 600;
      color: black;
      align-items: center;
      .left {
        height: 100%;
        width: 50%;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        color: #022741;
        align-items: center;
        justify-content: center;
        .linkContainer {
          height: auto;
          // width: auto;
          min-width: 150px;
          background-color: white;
          border: 2px solid #022741;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 600;
          color: black;
          margin: 20px auto 10px 30px;
          padding: 5px;
          .linkStyle {
            text-decoration: none;
            margin: 0 0 0 2px;
            color: #022741;
          }
          &:hover {
            background-color: #022741;
            .linkStyle {
              color: white;
            }
            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }
      .right {
        height: 100%;
        width: 50%;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        color: #022741;
        align-items: center;
        justify-content: center;
        .linkContainer {
          height: auto;
          // width: auto;
          min-width: 150px;
          background-color: white;
          border: 2px solid #022741;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 600;
          color: black;
          margin: 20px auto 10px 40px;
          padding: 5px;
          .linkStyle {
            text-decoration: none;
            margin: 0 0 0 2px;
            color: #022741;
          }
          &:hover {
            background-color: #022741;
            .linkStyle {
              color: white;
            }
            svg {
              path {
                fill: white;
              }
            }
          }
        }
        .linkContainer2 {
          height: 50%;
          //  width: auto;
          min-width: 150px;
          background-color: white;
          border: 2px solid #022741;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 600;
          color: #022741;
          align-items: center;
          margin: 20px 10px 10px 180px;
          .linkStyle {
            text-decoration: none;
            margin: 0 auto;
            color: #022741;
          }
        }
      }

      svg {
        width: auto;
        height: 35px;
        // margin-bottom: 5px;
        margin-right: 10px;
        path {
          fill: #022741;
        }
      }
    }
    .rightBox {
      height: 100%;
      width: 49%;
      background-color: blue;
      font-size: 18px;
      font-weight: 600;
      color: #022741;
      align-items: center;
      margin-left: auto;
      .leftIcon {
        height: 100%;
        width: 40%;
        background-color: white;
        font-size: 18px;
        font-weight: 600;
        color: #022741;
        align-items: center;
        justify-content: center;
        .linkContainer {
          height: auto;
          // width: auto;
          min-width: 150px;
          background-color: white;
          border: 2px solid #022741;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 600;
          color: #022741;
          margin: 20px auto 10px 30px;
          padding: 5px;
          .linkStyle {
            text-decoration: none;
            margin: 0 0 0 2px;
            color: #022741;
          }
          &:hover {
            background-color: #022741;
            .linkStyle {
              color: white;
            }
            svg {
              path {
                fill: white;
              }
            }
          }
        }
        svg {
          width: auto;
          height: 35px;
          // margin-bottom: 5px;
          margin-right: 10px;
          path {
            fill: #022741;
          }
        }
      }
      .video-section {
        width: 60%;
        background-color: white;
        height: 100%;
        padding: 15px;
        .video-container {
          width: 100%;
          height: 100%;
          background-color: gray;
          align-items: center;
          // margin-top: 20px;
          // margin-right: 30px;
          img {
            width: 100%;
            height: auto;

            object-fit: cover;
          }
        }
      }
    }
  }

  .midBlueRectContainer {
    height: 20%;
    width: 100%;
    background-color: #d4e1ec;
    margin-bottom: 10px;
    .containerNumberLeft {
      height: 100%;
      width: 49%;
      background-color: #d4e1ec;
      font-size: 18px;
      font-weight: 600;
      color: black;
      align-items: center;
      .blueBox {
        height: 100%;
        width: 49%;
        background-color: #027cba;
        align-items: center;
        justify-content: center;
        .headerNum {
          color: white;
          font-size: 50px;
          font-weight: 600;
          height: 60px;
        }
        .bodyText {
          color: white;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .blueBoxRight {
        height: 100%;
        width: 49%;
        background-color: #027cba;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        .headerNum {
          color: white;
          font-size: 50px;
          font-weight: 600;
          height: 60px;
        }
        .bodyText {
          color: white;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .containerNumberRight {
      height: 100%;
      width: 49%;
      background-color: #d4e1ec;
      font-size: 18px;
      font-weight: 600;
      color: black;
      align-items: center;
      margin-left: auto;
      .blueBox {
        height: 100%;
        width: 49%;
        background-color: #027cba;
        align-items: center;
        justify-content: center;
        .headerNum {
          color: white;
          font-size: 50px;
          font-weight: 600;
          height: 60px;
        }
        .bodyText {
          color: white;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .blueBoxRight {
        height: 100%;
        width: 49%;
        background-color: #027cba;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        .headerNum {
          color: white;
          font-size: 50px;
          font-weight: 600;
          height: 60px;
        }
        .bodyText {
          color: white;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .bottomContainer {
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
    .tContainer {
      // height: 210px;
      height: 100%;
      width: 100%;
      background-color: white;
      overflow-y: auto;
      box-sizing: border-box;

      .tableContainer {
        width: 100%;
        background-color: white;
        height: calc(100% + 20px);
        padding-right: 0;

        .thead tr {
          width: 100%;
          min-height: 50px;
          background-color: white;
          th {
            min-height: 50px;
          }
        }

        tbody {
          max-height: 300px;
          width: 100%;
          td {
            max-height: 80px;
          }
        }

        .sticky {
          position: sticky;
          top: 0;
          background-color: white;
        }
      }
    }

    // .tContainer::-webkit-scrollbar {
    //     -webkit-appearance: none;
    // }

    // .tContainer::-webkit-scrollbar:vertical {
    //     width:15px;
    // }

    // // .tContainer::-webkit-scrollbar-button:increment,.tContainer::-webkit-scrollbar-button {
    // //     display: none;
    // // }

    // // .tContainer::-webkit-scrollbar:horizontal {
    // //     height: 10px;
    // // }

    // .tContainer::-webkit-scrollbar-thumb {
    //     background-color: #797979;

    //     border: 2px solid #f1f2f3;
    //     height: 5px;
    // }

    // .tContainer::-webkit-scrollbar-track {

    //     height: 5px;
    // }

    // table{
    //     border-spacing: 0;
    //     display: flex;/*Se ajuste dinamicamente al tamano del dispositivo**/
    //     max-height: 200px; /*El alto que necesitemos**/
    //     overflow-y: auto; /**El scroll verticalmente cuando sea necesario*/
    //     overflow-x: hidden;/*Sin scroll horizontal*/
    //     width: 50%; /*El ancho que necesitemos*/
    //     border:1px solid gray;
    // }

    // thead{
    //     background-color: #f1eee9;
    //     position: fixed !important;/*el thead va ser siempre estatico**/
    // }

    // th{
    //     border-bottom: 1px solid #c4c0c9;
    //     border-right: 1px solid #c4c0c9;
    // }

    // th,td{
    //     font-weight: normal;
    //     margin: 0;
    //     max-width: 18.5vw; /**Ancho por celda*/
    //     min-width: 18.5vw;/**Ancho por celda*/
    //     word-wrap: break-word;/*Si el contenido supera el tamano, adiciona a una nueve linea**/
    //     font-size: 11px;
    //     height: 3.5vh !important;/*El mismo alto para todas las celdas**/
    //     padding: 4px;
    //     border-right: 1px solid #c4c0c9;
    // }
    // tr:nth-child(2n) {
    //     background: none repeat scroll 0 0 red;
    // }
    .graphData {
      height: 100%;
      width: 24%;
      margin-left: auto;
      // background-color: white;
      .chartContainer {
        @media only screen and (max-width: 768px) {
          display: grid;
        }
        background-color: white;
        width: 100%;
        height: 100%;

        .barContainer {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bottomContainerClient {
    width: 100%;
    height: 350px;
    margin-bottom: 10px;
    box-shadow: -1px 8px 12px -1px rgba(145,143,145,1);
    .tContainer {
      // height: 210px;
      height: 100%;
      width: 60%;
      background-color: white;
      overflow-y: auto;
      box-sizing: border-box;

      .tableContainer {
        width: 100%;
        background-color: white;
        height: calc(100% + 20px);
        padding-right: 0;

        .thead tr {
          width: 100%;
          min-height: 50px;
          background-color: white;
          th {
            min-height: 50px;
          }
        }

        tbody {
          max-height: 300px;
          width: 100%;
          td {
            max-height: 80px;
          }
        }

        .sticky {
          position: sticky;
          top: 0;
          background-color: white;
        }
      }
    }
    .graphData {
      height: 100%;
      width: 39%;
      margin-left: auto;
      .chartContainer {
        background-color: white;

        width: 100%;
        height: 100%;
        .barContainer {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.noAuth {
  width: 100%;
  height: 90vh;
  background-color: #027cba;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #3ac267, #3ac267);
  box-shadow: 0 3px 3px -5px #3ac267, 0 2px 5px #3ac267;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 0.1s ease 0.3s;
}
.modal {
  height: 80%;
  @media only screen and (max-width: 768px) {
    height: auto;
    display: flex;
    width: 95%;
    padding-left: 2%;
    padding-right: 2%;
  }
}
