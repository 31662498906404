.selectItemContainer {
  width: 100%;
  height: auto;
  background-color: white;
  align-items: center;
  margin-top: 5px;

  select {
    height: 60px;
    width: calc(100% / 7);
    margin-top: 30px;
    border-color: #027cba;
  }
  select:nth-of-type(1) {
    margin-left: auto;
  }

  .filterButton {
    width: 150px;
    height: 60px;
    border-radius: 0%;
    background-color: #027cba;
    color: white;
    margin-right: auto;
    margin-top: 30px;
    font-size: 18px;
    &:hover {
      background-color: #03487d;
      color: white;
    }
  }
}
.upDataContainer {
  align-items: center;
}
.productContainer {
  background-color: white;
  h2 {
    margin: 10px 0 0 40px;
  }
  .productDetailContainer {
    width: 95%;
    height: 600px;
    background-color: white;
    margin: 0 auto;
    /* .miniImgContainer{
      margin-top: 20px;
      margin-bottom: 20px;
      height: auto;
      background-color: white;
      width: 10%;
      flex-direction: column;

      justify-content: space-between;

      .imgContainer {
        background-color: white;
        width: 80%;
        height: calc(100% / 5);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    } */
    .mainImgContainer {
      width: 50%;
      height: auto;
      background-color: white;
      margin-top: 20px;

      margin-bottom: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dataContainer {
      width: 50%;
      height: auto;
      background-color: white;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      // padding: 0 10px;
      .availableProductCont {
        padding: 5px 0px;
        width: 95.6%;
        height: 48%;
        background-color: white;
        border-bottom: 2px solid rgba(0, 0, 0, 0.35);
        margin-left: 15px;
        .bold {
          font-weight: bold;
          font-size: 20px;
        }
        .labelNameContainer {
          display: flex;
          justify-content: space-between;

          width: 100%;
        }
        .title {
          font-size: 26px;
          font-weight: bold;
          @media screen and (max-width: 768px) {
            width: 2%;
          }

          span {
            display: none;
          }
        }
        .Cost {
          font-size: 26px;
          font-weight: bold;
          color: #027cbb;
          @media screen and (max-width: 768px) {
            width: 50%;
            margin-right: 20px;
          }
        }
        .availableStatus {
          font-size: 32px;
          color: #00ab59;
          font-weight: bold;
          display: block;
          margin-top: 20px;
          margin-right: 20px;
        }
        .notAvailableStatus {
          font-size: 25px;
          color: rgba(0, 0, 0, 0.5);
          font-weight: bold;
          display: block;
          margin-top: 20px;
          margin-right: 20px;
        }
        label {
          font-size: 16px;
        }
        .labelNotes {
          font-weight: 400;
          margin-top: 40px;
          font-size: 20px;
        }
      }
      .buyContainer {
        width: 95%;
        height: 50%;
        background-color: white;
        margin-left: 10px;
        padding: 5px;
        margin-top: 5px;
        .buttonCont {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          width: 100%;
          height: 100%;
        }
        .carDetail {
          font-style: italic;
        }

        .counter {
          margin-left: 20px;
          font-size: 18px;
          height: 3px;
          button:nth-of-type(1) {
            margin-left: 120px;
          }
          button {
            width: 40px;
            height: 40px;
            margin: 5px 10px 0 10px;
          }
        }
        .labelEnvio {
          font-weight: bold;
          margin-top: 120px;
          margin-left: 5px;
        }
        .btnAgregarCarrito {
          width: 100%;
          height: 65px;
          border-radius: 1%;
          background-color: #027cba;
          color: white;
          // margin-left: 25px;
          @media screen and (max-width: 375px) {
            font-size: 14px;
            width: 321px;
            height: 48px;
          }

          margin-top: 10px;
          font-size: 24px;
          font-weight: 500;
          &:hover {
            background-color: #03487d;
            color: white;
          }
        }
        .btnSolicitarPieza {
          width: 100%;
          height: 65px;
          border-radius: 1%;
          background-color: #027cba;
          color: white;
          // margin-left: 25px;

          margin-top: 184px;
          font-size: 24px;
          font-weight: 500;
          &:hover {
            background-color: #03487d;
            color: white;
          }
        }
      }
    }
  }
  .imgIzqCont {
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 570px;
    display: none;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
      cursor: pointer;
    }
    @media screen and (max-width: 375px) {
      display: none;
      img {
        display: none;
      }
    }
  }
  .specProductContainer {
    width: 95%;
    height: 300px;
    border-top: 0.744307px solid rgba(0, 0, 0, 0.35);
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    .specDataProduct {
      height: 100%;
      width: 55%;
      background-color: white;
      margin-top: 20px;
      h4 {
        margin-left: 10px;
        margin-top: 10px;
      }
      label {
        margin-left: 10px;
      }
      .variableContainer {
        width: 35%;
        height: 100%;
        background-color: white;
        flex-direction: column;
        label {
          margin-top: 20px;
        }
      }
      .valueContainer {
        width: 65%;
        height: 100%;
        background-color: white;
        flex-direction: column;
        label {
          margin-top: 20px;
        }
      }
      .labelAccesorios {
        width: calc(100% / 4);
        height: 100%;
        background-color: white;
      }
      .specDataVal {
        margin-left: 0px;
        font-weight: 700;
      }
    }
  }
  .detailProductContainer {
    height: 100%;
    width: 45%;
    background-color: white;
    margin-top: 20px;
    h4 {
      margin-left: 15px;
      margin-top: 10px;
    }
    label {
      margin-left: 15px;
    }
  }
  .labelAccesoriosContainer {
    width: 95%;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-top: 2px solid rgba(0, 0, 0, 0.35);
    h4 {
      margin-top: 30px;
    }
    label {
      margin-left: 200px;
      margin-top: 10px;
    }
    label:nth-of-type(1) {
      margin-left: 0px;
    }
  }
}

/* MEDIA QUERIES */
@media (max-width: 767px) {
  .productContainer {
    h2 {
      margin: 0;
      text-align: center;
      font-weight: bold;
    }
    .productDetailContainer {
      @media screen and (max-width: 375px) {
        width: 2200%;
      }
      flex-direction: column;
      height: auto;
      .mainImgContainer {
        width: 100%;
      }
      .dataContainer {
        width: 100%;
        .availableProductCont {
          .title {
            font-size: 22px;
            width: 100%;
            span {
              margin: 0;
              padding: 0;
              display: inline;
              float: right;
              text-align: right;
              font-size: 22px;
              color: #027cba;
              font-weight: bold;
            }
          }
          .availableStatus {
            font-size: 28px;
          }
        }
        .buyContainer {
          .Cost {
            display: none;
          }
          .counter {
            margin: 0 auto;
            button {
              margin: 0 !important;
            }
          }
          .btnAgregarCarrito {
            width: 100%;
            margin-top: 20px;
            border-radius: 2em;
          }
        }
      }
    }
    .specProductContainer {
      flex-direction: column;
      height: auto;
      .specDataProduct {
        width: 100%;
      }
      .detailProductContainer {
        width: 100%;
      }
    }
    .labelAccesoriosContainer {
      height: auto;
      .container-labelAccesorios {
        justify-content: center;
        .labelAccesorios {
          flex-direction: column;
          label {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
