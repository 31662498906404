@import "config/media.scss";

.mainContainer {
  width: 100%;
  height: auto;
  background-color: white;

  .topSection {
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    label {
      margin-left: 40px;
      font-size: 24px;
      font-weight: bold;
    }
    .table:nth-of-type(1) {
      margin-left: 40px;
      font-size: 24px;
      font-weight: bold;
    }

    .payButton {
      width: 200px;
      height: 60px;
      border-radius: 0%;
      background-color: #027cba;
      color: white;
      margin-left: auto;
      margin-right: 150px;
      font-size: 20px;
      font-weight: bold;
      &:hover {
        background-color: #03487d;
        color: white;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }

  .tableList {
    // margin-bottom: 10px;
    background-color: white;
    height: 40px;
    border-top: 2px solid rgba(0, 0, 0, 0.35);
    // margin-left: 40px;
    // margin-right: 40px;
    margin: 10px 40px;
    font-weight: bold;
    padding-top: 10px;
  }
  .table {
    width: calc(100% / 5);
    height: 35px;
    margin-bottom: 20px;
  }

  .table:nth-of-type(2) {
    margin-left: 10px;
  }
  .table:nth-of-type(3) {
    margin-left: 10px;
  }
  .table:nth-of-type(4) {
    margin-left: 10px;
  }

  // .total{
  //     height: 25px;
  //     background-color: white;
  //     margin-left: 40px;
  //     margin-right: 200px;
  //     justify-content: end;
  //     .totalLabel{
  //         font-size: 20px;
  //         font-weight: bold;
  //     }
  // }

  .total {
    height: auto;
    background-color: white;
    margin-left: 40px;
    margin-right: 40px;
    .spaceTotalPrice {
      width: calc(100% / 5);
      height: 50px;
      background-color: white;
    }
    .spaceTotalPrice:nth-of-type(2) {
      margin-left: 10px;
    }

    .spaceTotalPrice:nth-of-type(4) {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
      text-align: end;
      margin-right: 10px;
    }
    .totalLabel {
      width: calc(100% / 5);
      height: 50px;
      background-color: white;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .lastButton {
    justify-content: end;
    .payButtonBottom {
      width: 200px;
      height: 60px;
      border-radius: 0%;
      background-color: #027cba;
      color: white;
      // margin-left: auto;
      // margin-right: 40px;
      // margin-top: 20px;
      margin: 30px 80px 60px auto;
      font-size: 20px;
      &:hover {
        background-color: #03487d;
        color: white;
      }
    }
  }
}

.productList {
  flex-direction: column;
  .containerProducts {
    height: 280px;
    background-color: white;
    margin-left: 40px;
    margin-right: 40px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.35);
    margin-bottom: 20px;

    #containers {
      width: calc(100% / 5);
      height: auto;
      background-color: white;
    }

    #containers:nth-of-type(2) {
      margin-left: 10px;
    }
    #containers:nth-of-type(3) {
      margin-left: 10px;
    }
    #containers:nth-of-type(4) {
      margin-left: 10px;
    }

    .imgContainer {
      width: 100%;
      height: 80%;
      background-color: white;
      margin-top: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .delete {
      color: rgba(2, 124, 187, 0.8);
      cursor: pointer;
      margin-top: 25px;
    }
    .infoSection {
      display: flex;
      flex-direction: column;
    }
    .secondContainer {
      display: flex;
      width: 80%;
      justify-content: space-evenly;

      .quantityContainer {
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-around;
      }
      .specProduct {
        h4 {
          margin-top: 5px;
          margin-left: 5px;
        }
        .valueContainer {
          width: 100%;
          height: 100%;
          background-color: white;
          flex-direction: column;
          height: 100%;
          label {
            margin-top: 15px;
            font-weight: bold;
            text-align: left;
          }
          label:nth-of-type(1) {
            margin-top: 1px;
          }
        }
      }
    }
    .unitPrice {
      align-items: center;

      label {
        font-size: 20px;
      }
    }
    .countContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .exists {
        color: green;
      }
      .counter {
        font-size: 18px;
        align-items: center;

        button:nth-of-type(1) {
          margin-left: 0px;
        }
        button {
          width: 40px;
          height: 40px;
          margin: 5px 10px 0 10px;
          align-items: center;
          svg {
            margin: auto auto;
          }
        }
        .counterNum {
          // border-bottom: 3px solid #027CBB;
          display: inline;
          border-bottom: 3px solid #027cbb;

          // position: relative;
          // font-size: 16px;
          line-height: 20px;
          // text-decoration: underline;
        }
      }
    }
    .totalPrice {
      align-items: center;

      label {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
