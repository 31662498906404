.graphRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 4%;
    justify-items: start;
    margin-top: 2%;
    height: 50%;
    .graphColumn{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    @media(max-width: 1499px){
        height: 90%;
    }
    @media(max-width: 991px){
        grid-template-columns: 1fr;
        height: auto;
        .graphColumn{
            margin-top: 2%;
            min-height: 500px;
        }
    }
    @media(max-width: 767px){
       margin-top: 0;
       width: auto;
       height: auto;
       margin-bottom: 0;
       .graphColumn{
        margin-bottom: 5%;
        min-height: 450px;
       }
    }
}