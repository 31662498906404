.container {
	width: 100%;
  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    padding: 10px;
  }

  input,
  select {
    width: 100%;
    background: #f9f9f9a9;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 14px;
    color: rgb(0, 0, 0);
    outline: none;
    font-weight: 300;
  }
  button {
    width: 100%;
    padding: 10px;
    background-color: #027CBB;
    color: white;
    margin: 0 auto;
    border: 0;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  .progress-bar {
    width: 100%;
    padding: 5px;
  }

  .rccs__card--unknown > div {
    background: #ececec0e;
    border-radius: 15px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  }
  .rccs__card--unknown > div:before {
    backdrop-filter: blur(10px);
    content: '';
    height: 100%;
    width: 100%;
  }
}