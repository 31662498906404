@import url('https://fonts.googleapis.com/css?family=Roboto');

.titeColumns {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #022741;
    text-align: center;
}

.textContainTable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #022741;
    text-align: center;
}

.bodyTable tr:nth-child(even) {
    border-collapse: separate;
    border-spacing: 10px 5px;
    background: #EAF0F6;
    border: 15px solid white;
}

.bodyTable tr:nth-child(odd) {
    border-collapse: separate;
    border-spacing: 10px 5px;
    background: #EAF0F6;
    border: 15px solid white;

}

