.GeneralDetails {
  width: 50%;
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 8%;
  margin-bottom: 4%;
  background-color: #027cba;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  table {
    width: 100%;
    color: #fff;
    border: 2px solid white;
    border-radius: 3px;
    tr {
      border: 2px solid white;
      border-radius: 3px;
    }
    td {
      border: 2px solid white;
      border-radius: 3px;
    }
  }
}

.iconopenDetail {
  color: rgba(0, 0, 255, 0.846);
  position: absolute;
  bottom: 0;
  right: 6%;
  transform: scale(1.2);
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.iconopenDetail2 {
  color: rgba(0, 0, 255, 0.671);
  position: absolute;
  bottom: 0;
  right: 6%;
  transform: rotate(180deg) scale(1.2);
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
