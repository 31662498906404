#TopLeftImageContainer{
  width: 100%;
  height: 100%;     
}
#TopRightImageContainer{
  width: 100%;
  height: 50%;      
}
#containerHorizontalImage{
  width: calc(100% / 4);
  height: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-radius: 0%;


  img{
    margin-left: 11px;
    object-fit: contain;
    }
}
#containerHorizontalImage1{
  width: calc(100% / 4);
  height: 100%;
  border-right: 1px solid white;
  // box-shadow: 1px 1px;
  border-radius: 0%;
  
  img{
    margin-left: 10px;
    object-fit: contain;
    }
  
}
#containerHorizontalImage2{
  width: calc(100% / 4);
  height: 100%;
  border-left: 1px solid white;
  border-radius: 0%;

  img{
    margin-left: 10px;
    object-fit: contain;
    }
  
}
#containerNewItems{
  width: 100%;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  .textImg {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    color: black;
  }
}
#containerNewItemsImg{
  width: 100%;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  flex-direction: row;
}
#containerNewItemsImg2{
  width: 100%;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  flex-direction: row;
  margin: 0 10px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// .textImg {
//   text-align: center;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   color: black;
// }


.newTag{
   width: 100%;
   height: 30px;
  svg {
    transform: rotate(-45deg);
    margin-left:20px;
    
    path {
      fill: #027CBB;
    }
  }
  .textImg {
    display: inline-block;
    text-align: center;
    position: absolute;
    z-index: 1000;
    top: 30px;
    left: 43px;
    transform: translate(-50%, -50%);
    color: white;
    label{
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
  }
}