.leftAside {
  width: 15%;
  display: flex;
  //   background-color: green;
  padding: 20px 0;
  @media only screen and (max-width: 768px) {
    width: 220px;
  }
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    width: 20%;
  }
  background-color: #027cba;
  height: 90vh;
  // position: fixed;
  // top: 10;
  // left: 0;
  .listContainer {
    width: 100%;
    list-style-type: none;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // background-color: yellow; // padding: 0 10px;
    .list {
      border-left: 4px solid #027cba;
      //   background-color: brown;
      // border-bottom: 1px solid white;
      // background-color: yellow;
      @media only screen and (max-width: 768px) {
        margin-left: -13%;
        white-space: normal;
      }
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      button {
        width: 100%;
        height: 100%;
        font-weight: 600;
        background-color: #027cba;
        border: none;
        color: #c4c4c4;
        font-size: 18px;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 8px;
          path {
            fill: rgba(2, 39, 65, 0.3);
          }
        }
      }
      svg {
        width: 25px;
        height: 25px;
        margin-right: 8px;
        path {
          fill: rgba(2, 39, 65, 0.3);
        }
      }
      img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }
      .linkStyle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #c4c4c4;
        text-decoration: none;
        margin: 20px 0;
        padding: 0 10px;
        font-size: 18px;

        // background-color: pink;
      }
      &:hover {
        font-size: 20px;
        background-color: rgba(255, 255, 255, 0.08);
        // border-left: 4px solid white;

        .linkStyle {
          color: white;
        }

        button {
          background-color: rgba(255, 255, 255, 0.08);
        }
        svg {
          path {
            fill: white;
          }
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
      .buttonCerrar {
        color: #ffffffcc;
        text-decoration: none;
        margin: 20px 0;
        margin-right: auto;
        font-size: 18px;
        path {
          fill: #ffffffcc;
        }
      }
      &:hover {
        font-size: 20px;
        background-color: rgba(237, 19, 19, 0.08);
        border-left: 4px solid white;

        .buttonCerrar {
          color: white;
        }
        button {
          background-color: rgba(255, 255, 255, 0.08);
        }
        svg {
          path {
            fill: #ffffffcc;
          }
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    /* .list:nth-of-type(1){
            margin-top: 20px;
        }
        .list:nth-of-type(9){
            margin-top: 50px;
        } */
  }
}

.buttons-paginacion-obsoleto {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  font-size: 16px;
  font-weight: 500;
  button {
    display: flex;
    padding: 4px 6px;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #027cba;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
  }
  .margin-right {
    margin-right: 5px;
  }
  .margin-left {
    margin-left: 5px;
  }
}

@media (max-width: 991px) {
  .leftAside {
    display: none !important;
  }
}
