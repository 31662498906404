//Cliente
#mainContainer{
    width: 100%;
    height: auto;
    background-color: white;
    #Container{
        height: 50%;
        background-color: white;
        margin: 40px 200px 40px 200px;
        #headerPagination{
            width: 100%;
            height: 26px;
            background-color: white;
            justify-content: space-between;
            align-items: center;
            label{
                font-size: 24px;
                font-weight: 600;
            }
            button{
                border: none;
                box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 8px -1px, rgba(0, 0, 0, 0.3) 0px 1px 5px -1px;
                color: white;
                background-color: white;
            }
            #leftBtn{
                margin-right: 5px;
            }
        }
        #imgSlider{
            width: 100%;
            height: 320px;
            margin-top: 20px;
            
            #leftImg{
                width: 48%;
                height: 100%;
                background-color: white;
                border: 3px solid rgb(182, 181, 181);
                border-radius: 5px;  
                flex-direction: column;
                margin-right: auto;
                justify-content: center;
                #longBar{
                    width: 50%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
                #shortBar{
                    width: 45%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
            }
            #rightImg{
                width: 48%;
                height: 100%;
                background-color: white;
                border: 3px solid rgb(182, 181, 181);
                border-radius: 5px;  
                margin-left: auto;
                flex-direction: column;
                justify-content: center;
                #longBar{
                    width: 50%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
                #shortBar{
                    width: 45%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
            }
            
        }
    }
    #Container2{
        margin-top: 60px;
        height: 50%;
        background-color: white;
        margin: 40px 200px 40px 200px;
        #headerPagination{
            width: 100%;
            height: 26px;
            background-color: white;
            justify-content: space-between;
            align-items: center;
            label{
                font-size: 24px;
                font-weight: 600;
            }
            button{
                border: none;
                box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 8px -1px, rgba(0, 0, 0, 0.3) 0px 1px 5px -1px;
                color: white;
                background-color: white;
            }
            #leftBtn{
                margin-right: 5px;
            }
        }        
        #imgSlider{
            width: 100%;
            height: 380px;
            margin-top: 15px;
            justify-content: space-between;
            #leftImg{
                width: 30%;
                height: calc(100% - 26px);
                background-color: white;
                border: 3px solid rgb(182, 181, 181);
                border-radius: 5px;  
                flex-direction: column; 
                justify-content: center;
                #longBar{
                    width: 50%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
                #shortBar{
                    width: 45%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
            }
            #midImg{
                width: 30%;
                height: calc(100% - 26px);
                background-color: white;
                border: 3px solid rgb(182, 181, 181);
                border-radius: 5px;  
                flex-direction: column;
                justify-content: center;
                #longBar{
                    width: 50%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
                #shortBar{
                    width: 45%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                }    
            }
            #rightImg{
                width: 30%;
                height: calc(100% - 26px);
                background-color: white;
                border: 3px solid rgb(182, 181, 181);
                border-radius: 5px;  
                flex-direction: column;
                justify-content: center;
                 #longBar{
                    width: 50%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                } 
                #shortBar{
                    width: 45%;
                    height: 30px;
                    background-color:rgb(182, 181, 181);
                    border-radius: 5px; 
                    margin: 15px auto;

                }  
                
            }
            
        }
    }
}


// Top img


.image-area2 {
    width: 100%;
    height: 380px;
    background-color: white;
    border: 3px solid rgb(182, 181, 181);
    border-radius: 5px;
    
    .filterImg {
      
      height: 70px;
      display: inline-block;
      text-align: center;
      position: absolute;
      z-index: 1000;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: black;
    //   button:nth-of-type(1){
    //     border-top: 1px solid rgb(182, 181, 181);
    //     border-left: 1px solid rgb(182, 181, 181);
    //     border-bottom: 1px solid rgb(182, 181, 181); 
    //     border-right: 5px solid white; 
    // }
    // button:nth-of-type(2){
    //     border: 10px solid rgb(182, 181, 181);
        
    // }
    // button:nth-of-type(3){
    //     border-top: 1px solid rgb(182, 181, 181);
    //     border-bottom: 1px solid rgb(182, 181, 181);
    //     border-right: 5px solid white; 
    //     border-left: 1px solid white;    }
    // button:nth-of-type(4){
    //     border-top: 1px solid rgb(182, 181, 181);
    //     border-bottom: 1px solid rgb(182, 181, 181);
    //     border-right: 5px solid white;
    //     border-left: 1px solid white;     }
    // button:nth-of-type(5){
    //     border-top: 1px solid rgb(182, 181, 181);
    //     border-right: 1px solid rgb(182, 181, 181);
    //     border-bottom: 1px solid rgb(182, 181, 181); 
    // }
      .dropdown{
        background-color: white;
        color: black;
        
        .btn-secondary{
          background-color: white;
          color: black;
          border-radius: 0;
          border: 1px solid rgb(182, 181, 181);
          
          min-width: 200px;
          height: 70px;
          font-size: 16px;
        }
        
      }

      
      .btn-check:focus+.btn, .btn:focus{
        box-shadow: none;
      }
      .dropdown-menu{
        width: 100%;
        border-radius: 0%;  
        padding: 10px;
      }
      .dropdown-item{
        margin-top: 5px;
        color: gray;
        
      }
      .dropdown-item.active, .dropdown-item:active{
        background-color: #027cba;
        color: white;
      }
      
      .dropdown-menu.show{
        top: -1px !important;
        font-size: 15px;
      }
      
      .searchbarDropdownContainer{
        padding: 0;
        width: 100%;
         display: flex;
         align-items: center;
        .searchbarDropdown{
          width: 96%;
          padding: 0;
          margin: 0 auto;
          border: 1px solid #027cba;
          outline: none;
        }

      }
      
      .filterButton{
        width: 150px;
        border-radius: 0%;
        background-color:rgb(182, 181, 181);
        color: white;
        
      }
    }
  }



