.modalCart {
  width: 870px;
  height: 934px;
  margin-top: 5%;
  min-height: 35vh;
  background-color: white;
  border-radius: 20px;
  overflow-y: scroll;
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    * {
      margin-top: 5%;
      margin-right: 5%;
      font-size: 2rem;
    }
  }

  .nocontent {
    width: 100%;
    height: 22vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }

  .articles {
    display: flex;
    flex-direction: row;
    background-color: rgba(2, 124, 187, 0.15);
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    height: 15vh;
    align-items: center;
    width: 100%;
    height: 157px;

    * {
      color: black;
    }

    h6 {
      font-size: 1rem;
      margin: 0;
    }
    p {
      margin: 0;
    }

    .secondContainer {
      width: 100%;
      height: 100%;
      justify-content: space-around;
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    .delBtn {
      color: #2d92c7;
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 16px;
    }

    .imgContainer {
      margin: 2%;
      width: 217px;
      height: 140px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .infoContainer {
      width: 40%;
      h6 {
        font-weight: bold;
        font-size: 22px;
      }
      p {
        font-size: 16px;
      }
    }
    .boldFont {
      font-weight: bold;
    }
    .basicContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 !important;
    }

    .quantityContainer {
      width: 15%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .amountContainer {
        label {
          font-size: 16px;
        }
        @extend .basicContainer;
        margin-bottom: 15%;
      }
      .qtyElements {
        @extend .basicContainer;
      }
      .counterNum {
        width: 1.5vw;
        height: 1.5vw;
        margin: 0;
        padding: 0;
        background-color: white;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        // border-bottom: 3px solid #027CBB;
        display: inline;
        border-bottom: 3px solid #027cbb;

        // position: relative;
        // font-size: 16px;
        line-height: 20px;
        // text-decoration: underline;
      }
    }

    .countButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
    }

    .counterNum {
      background-color: white;
      text-align: center;
      width: 2vw;
      height: 2vw;
    }

    hr {
      background-color: black;
      color: black;
      width: 1px;
      height: 90%;
      margin-right: 2%;
    }
  }

  .btn {
    width: 180px;
    height: 45px;
    border-radius: 0%;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .cancelBtn {
    background-color: #f06062;
    color: white;
    width: 277px;
    height: 79px;
    font-size: 32px;
    &:hover {
      background-color: #b4494a;
      color: white;
    }
  }

  .payButton {
    background-color: #027cba;
    color: white;
    width: 277px;
    height: 79px;
    font-size: 32px;
    &:hover {
      background-color: #03487d;
      color: white;
    }
  }

  .mainContainer {
    width: 100%;
    min-height: 22vh;
    color: transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .buySection {
    display: flex;
    justify-content: flex-end;
    margin-right: 8%;
    font-size: 32px;
    font-weight: bold;
    span {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .lastSection {
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media only screen and (max-width: 420px) {
  #parte {
    font-size: 11px;
  }
  .modalCart {
    width: 335px;
    height: 720px;
    align-items: space-around;

    border-radius: 0px;
    h6 {
      font-size: 16px !important;
      font-weight: 400 !important;
      margin: 0;
    }
    p {
      font-size: 0.5rem;
      margin: 0;
    }

    .buySection {
      font-size: 18px;
      font-weight: 400;
      display: flex;
      justify-content: space-around;
      span {
        font-size: 14px;
      }
    }
    .existencias {
      display: none;
    }
    .articles {
      width: 323px;
      height: 104px;
      .imgContainer {
        margin: 0;
        width: 38%;
        margin-right: 5%;
        height: 100%;
        img {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .secondContainer {
        height: 100%;
        flex-wrap: wrap;
        flex-direction: row;
      }

      hr {
        display: none;
      }

      .delBtn {
        color: #2d92c7;
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 0.6rem;
      }

      .infoContainer {
        width: 100%;
        height: 50%;
        margin: 0;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
      }

      .quantityContainer {
        display: flex;
        flex-direction: row;
        height: 50%;
        width: 100%;
        align-items: center;
        justify-content: space-around;

        .counterNum {
          height: 6vw;
          width: 6vw;
          margin: 0;
          padding: 0;
        }

        label {
          display: none;
        }
        #precio {
          font-size: 18px;
          font-weight: 500;
          span {
            font-size: 16px;
          }
          #delPrecio {
            display: none;
          }
        }
      }
    }

    .lastSection {
      height: 25%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      .cancelBtn {
        font-size: 14px;
        border-radius: 50px;
        background-color: #f06062;
        color: white;
        width: 292px;
        height: 45px;
        margin-top: 10px;
        &:hover {
          background-color: #b4494a;
          color: white;
        }
      }

      .payButton {
        width: 292px;
        height: 45px;
        font-size: 14px;
        border-radius: 50px;
        background-color: #027cba;
        color: white;
        &:hover {
          background-color: #03487d;
          color: white;
        }
      }
    }
  }
}

.dropable {
  display: none !important;
}

@media only screen and (max-width: 768px) and (min-width: 421px) {
  .modalCart {
    width: 80%;
    min-height: auto;
    h6 {
      font-size: 0.6rem !important;
      margin: 0;
    }
    p {
      font-size: 0.5rem;
      margin: 0;
    }

    .buySection {
      font-size: 1rem;
    }

    .articles {
      height: 12vh;
      .imgContainer {
        margin: 0;
        width: 38%;
        margin-right: 5%;
        height: 100%;
        img {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .secondContainer {
        height: 100%;
        flex-wrap: wrap;
        flex-direction: row;
      }

      hr {
        display: none;
      }

      .delBtn {
        color: #2d92c7;
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 0.6rem;
      }

      .infoContainer {
        width: 100%;
        height: 50%;
        margin: 0;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
      }

      .quantityContainer {
        display: flex;
        flex-direction: row;
        height: 50%;
        width: 100%;
        align-items: center;
        justify-content: space-around;

        .counterNum {
          height: 3vw;
          width: 3vw;
          margin: 0;
          padding: 0;
        }

        label {
          display: none;
        }
        #precio {
          font-size: 0.7rem;
          #delPrecio {
            display: none;
          }
        }
      }
    }

    .lastSection {
      height: 15vh;
      display: flex;
      flex-direction: column-reverse;
      .cancelBtn {
        font-size: 1rem;
        border-radius: 50px;
        background-color: #f06062;
        color: white;
        &:hover {
          background-color: #b4494a;
          color: white;
        }
      }

      .payButton {
        font-size: 1rem;
        border-radius: 50px;
        background-color: #027cba;
        color: white;
        &:hover {
          background-color: #03487d;
          color: white;
        }
      }
    }
  }
}
