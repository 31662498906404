.switchContainer{
    width: calc(60% + 150px);
    height: 80%;
    background-color: white;
    margin: 0 auto;
    h2{
        margin: 20px 0 20px 40px;
    }
    .labelContainer{
        width: 60%;
        // background-color: red;
        align-items: center;
        justify-content: space-between;
        margin: 10px 100px 5px 80px;
        font-size: 18px;
    }
    
}