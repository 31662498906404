nav {
	width: 100%;
	height: 10vh;
	display: flex;
	align-items: center;
	justify-content: space-between;

	// background-color: peru;

	img {
		width: 150px;
		height: auto;
		margin-left: 20px;
	}

	.icons {
		margin-top: 15px;
		margin-right: 40px;
		width: 180px;
		img {
			width: 25px;
			height: 25px;
			margin-left: 0;
		}
	}

	button {
		border: none transparent;
		background-color: transparent;
		img {
			filter: invert(8%) sepia(48%) saturate(4089%) hue-rotate(188deg)
				brightness(99%) contrast(98%);
		}

		label {
			z-index: -1;
			position: relative;
			right: 15%;
			bottom: 45%;
			background-color: #027cbb;
			color: white;
			width: 20px;
			height: 20px;
			border-radius: 150px;
		}
	}
}

@media only screen and (max-width: 420px) {
	.icons {
		justify-content: space-evenly !important;
		align-items: center;
		width: 40% !important;
		margin: 0 !important;
		img {
			width: 4.5vw !important;
			height: 4.5vw !important;
		}
		label {
			width: 15px;
			height: 15px;
			font-size: 0.8em;
			bottom: 5px;
		}
	}

	.contLogo {
		width: 60%;
		.Logo {
			margin-left: 10%;
			width: 60%;
		}
	}
}

@media only screen and (max-width: 768px) and (min-width: 421px) {
	nav {
		height: 15vh !important;
		.icons {
			justify-content: space-evenly !important;
			align-items: center;
			width: 30% !important;
			margin: 0 !important;
			img {
				width: 2.5vw !important;
				height: 2.5vw !important;
			}
			label {
				width: 15px;
				height: 15px;
				font-size: 0.8em;
				bottom: 10px;
			}
		}
	}
}
