.mainHomeTop {
  width: 100%;
  background-color: #d4e1ec;
  // z-index: -2;
  // position:absolute;
  .menu-area {
    height: 70px;
    width: 100%;
    margin-top: 5px;
    background-color: white;

    button {
      height: 100%;
      width: calc(100% / 7);
      background-color: white;
      border-radius: 0;
      border: none;
      color: black;

      &:hover {
        background-color: #027cba;
        color: white;
      }
      svg path {
        fill: "red";
      }
    }
    button:nth-of-type(1) {
      margin-left: 0;
      display: block;
    }

    #styleButton:focus ~ .hamburgerActive {
      margin-left: 400px;
    }
  }
  .hamburgerActive {
    background-color: white;
    width: 14%;
    /*  height:100%; */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    z-index: 9999;
    transition: height 1s;
    .title {
      font-weight: bold;
      font-size: 17px;
    }
    label {
      margin: 3.5px 0;
    }
    .scrollSectionHam {
      width: 100%;
      background-color: white;
      overflow: auto;
      padding: 5px;
      label {
        margin: 3.5px 0;
      }
    }
  }

  .image-area {
    width: 100%;
    height: 380px;
    // background-color: yellow;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .textImg {
      display: inline-block;
      text-align: center;
      position: absolute;
      z-index: 1000;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: black;

      .dropdown {
        background-color: white;
        color: black;

        .btn-secondary {
          background-color: white;
          color: #75787b;
          border-radius: 0;
          border: none;
          min-width: 200px;
          height: 70px;
          font-size: 16px;
          // :focus{
          //   outline-style: solid;
          //   outline-color: red;
          // }
        }
      }

      .btn-check:focus + .btn,
      .btn:focus {
        box-shadow: none;
      }
      .dropdown-menu {
        width: 100%;
        border-radius: 0%;
        padding: 10px;
      }
      .dropdown-item {
        margin-top: 5px;
        color: gray;
      }
      .dropdown-divider {
        border: 1px solid #027cbb;
      }
      .dropdown-item.active,
      .dropdown-item:active {
        background-color: #027cba;
        color: white;
      }

      .dropdown-menu.show {
        top: -1px !important;
        font-size: 15px;
        // border: 1px solid #027cba;

        //  position: relative !important;
        //  z-index: 1000;
      }

      .show {
        .btn-secondary {
          // color: red;
          font-weight: bold;
        }
      }

      .searchbarDropdownContainer {
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;

        .searchbarDropdown {
          width: 96%;
          // margin: 0 auto;
          border: 1px solid #027cba;
          outline: none;
          padding-left: 35px;
        }
      }
      .searchbarDropdownContainer:before {
        content: "";
        position: absolute;
        left: 18px;
        top: 0;
        bottom: 222px;
        width: 20px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='gray'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E")
          center / contain no-repeat;
      }

      .filterButton {
        width: 150px;
        border-radius: 0%;
        background-color: #027cba;
        color: white;
        &:hover {
          background-color: #03487d;
        }
      }
      // ul {
      //   list-style: none;
      // }
      // > ul {
      //   list-style: none;
      //   padding: 0%;
      //   margin: 0%;
      //   display: flex;
      //   background-color: white;
      //   color: black;
      //   > li {
      //     position: relative;
      //     cursor: pointer;
      //     font-size: 16px;
      //     color: black;
      //     float: left;
      //     width: 200px;
      //     height: 50px;
      //     line-height: 50px;
      //     letter-spacing: 1px;
      //   }
      // }
      // > #ulTop > .menuItem {
      //   color: black;
      //   .dropIcon {
      //     width: 15px;
      //     height: 25px;
      //     margin-top: 12px;
      //     margin-left: 5px;
      //     -webkit-transition: all 0.4s ease-out;
      //     -moz-transition: all 0.4s ease;
      //     -ms-transition: all 0.4s ease;
      //   }
      //   &:hover {
      //     color: white;
      //     .dropIcon {
      //       -webkit-transform: rotate(-90deg);
      //       -moz-transform: rotate(-90deg);
      //       -ms-transform: rotate(-90deg);
      //       transform: rotate(-180deg);
      //       margin-top: 0;
      //       margin-bottom: 5px;
      //     }
      //   }
      // }
    }
    // li:hover {
    //   background-color: #03487d;
    // }
    // .dropdown {

    //   top: 100%;
    //   left: 0;
    //   width: 100%;
    //   padding: 0;
    //   > .dropItem {
    //     color: black;
    //   }
    //   li {
    //     background-color: #d4e1ec;
    //     display: none;
    //     &:hover {
    //       background-color: #027cba;
    //       color: white;
    //     }
    //   }
    // }
    // li:hover > .dropdown {
    //   perspective: 1000px;
    //   li {
    //     display: block;
    //     transform-origin: top center;
    //     opacity: 0;
    //     &:nth-child(1) {
    //       animation: animate 300ms ease-in-out forwards;
    //       animation-delay: -150ms;
    //     }
    //     &:nth-child(2) {
    //       animation: animate 300ms ease-in-out forwards;
    //       animation-delay: 0ms;
    //     }
    //     &:nth-child(3) {
    //       animation: animate 300ms ease-in-out forwards;
    //       animation-delay: 150ms;
    //     }
    //     &:nth-child(4) {
    //       animation: animate 300ms ease-in-out forwards;
    //       animation-delay: 300ms;
    //     }
    //     @keyframes animate {
    //       0% {
    //         opacity: 0;
    //         transform: rotateX(-90deg);
    //       }
    //       50% {
    //         transform: rotateX(20deg);
    //       }
    //       100% {
    //         opacity: 1;
    //         transform: rotateX(0deg);
    //       }
    //     }
    //   }
    // }
  }
}
.mainMidContainer {
  background-color: #d4e1ec;
  // z-index: 1;
  // position:absolute;

  .containerMid2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .containerNewItems2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    flex-direction: column;
  }
  .containerMid {
    max-width: 95%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    img {
      margin-right: 15px;
    }
    .leftContainer {
      width: 50%;
      height: 400px;
      background-color: white;
      color: white;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      .textImg {
        color: white;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .rightContainer {
      width: 50%;
      height: 400px;
      margin-left: 10px;
      background-color: white;
      color: white;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      .textImg {
        color: white;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .containerHorizontal {
      width: 100%;
      height: 150px;
      background-color: white;
      align-items: center;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      img {
        width: 80px;
        height: 80px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 15px;
      }
      .textImg {
        color: black;
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .containerNewItems {
      width: 100%;
      height: 500px;
      background-color: white;
      border: none;
      .title {
        font-weight: bold;
        font-size: 18px;
      }
      .description {
        font-size: 18px;
      }
      .containerItemsMargin1 {
        margin: 0 5px 0 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
      .containerItemsMargin2 {
        margin: 0 10px 0 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
      .textImg {
        font-size: 18px;
        font-weight: 500;
        margin-left: 20px;
        color: black;
        margin-top: 10px;
      }
      img {
        margin-top: 40px;
        height: 60%;
        object-fit: contain;
      }
    }
    .containerItems2 {
      width: 100%;
      height: 250px;
      background-color: white;
      .containerNewItemsImg1 {
        margin-right: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
      img {
        height: auto;
        width: 100%;
      }
    }
    .containerItems3 {
      background-color: none;
      margin-bottom: 20px;
      img {
        height: 200px;
        width: 50%;
        object-fit: contain;
      }
      .textImg {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .containerSponsors {
      width: 100%;
      height: 300px;
      background-color: white;
      margin-bottom: 30px;

      .containerSponsors1 {
        width: 100%;
        height: 100%;
        flex-direction: row;
        border: 2px solid white;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.searchWihtOutVehicle {
  position: absolute;
  margin-top: -40px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.btn-drop {
  height: 85%;
  /*  width: calc(100% / 7); */
  box-sizing: content-box;
  margin-top: 5px;
  background-color: white;
  border-radius: 0;
  color: black;
  font-size: 16px;
  border-left-color: transparent;
  border-right: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  width: 100%;

  /*  &:hover {
    font-weight: bold;
    color: black;
    border-top-color: #027CBB !important;
    border-top-width: thick;
    border-left-color: transparent;
    border-right: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
  } */
  // svg path {
  //   fill: 'red';
  // }
}
.btn-drop-new {
  font-weight: bold;
  color: black;
  border-top-color: #027cbb !important;
  border-top-width: thick;
  border-left-color: transparent;
  border-right: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
