.porVencerContainer{
    width: 100%;
    height: 80%;
    
    padding: 10px 10px 10px 10px;
    
    .header{
        width: 80%;
        height: 10%;
        background-color: white;
        margin: 0 auto;
        font-size: 18px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 10px 20px;

        .table:nth-of-type(1){
            font-size: 24px;
            width: 20%;
            display: flex;
            margin-top: 0px;
            margin-left: 25px;
            
        }
        .table:nth-of-type(2){
            margin-left: 20px;
            
        }
        .table{
            height: 100%;
            width: 16%;
            display: flex;
            align-items: center;
            margin-top: 5px;
            
        }

        
    }

    .content{
        width: 80%;
        height: 90%;
        background-color: white;
        margin: 0 auto;
        font-size: 18px;
        font-weight: bold;
        
        
        padding: 10px;
        .itemCard{
            width: 100%;
            height: 25%;
            background-color: white;
            margin-bottom: 20px;
            

            .imgCol{
                width: 20%;
                height: 100%;
                margin-right: 20px;

                .imgContainer{
                    width: 49%;
                    height: 100%;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;


                    }
                }
                .dataContainer{
                    width: 49%;
                    height: 100%;
                    padding-left: 5px;
                    font-weight: bold;
                    justify-content: center;

                    .detailLabl{
                        font-size: 10px;
                        
                    }
                }
                
            }
            .numbCol{
                width: 16%;
                height: 100%;
                align-items: center;
            }

            .numbCol:first{
                margin-left: 20px;
                width: 16%;
                height: 100%;
                
            }
            #red{
                color: #FF0000;
            }
            #yellow{
                color: #FB8B23;
            }

        }
        
    }
}