footer{
    overflow-y: hidden; 
    color: white;
    height: 20vh;
    position: relative;
    z-index: -1;
    svg{
        width: 15px;
        height: 15px;
    }

    @media only screen and (max-width: 425px) {
        height: 65vh;
        width: 100%;
    }

    @media only screen and (max-width: 768px) and (min-width: 426px) {
        width: 100%;
        height: 45vh;
        margin-top: 15%;
    }
}
.textcuad{
    height: auto;
    width: 100%;
    background-color: #012840;
    @media only screen and (max-width: 425px){
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        }
    ul li {
        text-decoration: none;
        list-style-type: none;
    }
}

.textLeft{
    height: 100%;
    width: 30%;
    @media only screen and (max-width: 425px){
        width: 100%;
        height: auto;
        
        }

    .title{
        font-size: 16px;
        font-weight: 500;
        margin-top: 8px;
        margin-left: 25px;
        @media only screen and (max-width: 425px){
            margin-top: 30px;
            font-size: 20px;
            
            }
    }
    .divIcons{
        margin-left: 20px;
        width: 100px;
        
        
    }
    ul{
        padding: 0px;
        margin-left: 30px;
        font-size: 10px;
        @media only screen and (max-width: 425px){
            
            font-size: 14px;
            margin-bottom: 30px;
            
            }
        li{
            margin-top: 2px;
        }
    }
}
.textCenter{
    width: 30%;
    font-size: 12px;
    flex-direction: column;
    @media only screen and (max-width: 425px){
        width: 100%;
        height: auto;
        font-size: 14px;
        text-align: center;
        margin-top: auto;
        margin-bottom: 10px;
    
        label{
            margin-bottom: 0px !important;
        }
        
    }
    label{
        margin-bottom: 8px;
    }
}
.textRight{
    width: 30%;
    text-align: right;
    padding: 5px;
    @media only screen and (max-width: 425px){
        width: 100%;
        height: auto;
        text-align: start;
        padding: 0;
        svg{
            width: 20px;
            height: 20px;
        }
        
    }

    .textop{
        word-wrap: break-word;
        width: 40%;
        margin-top: 5px;
        margin-right: 25px;
        font-size: 11px;

    }
    .divIcons {
        width: 40%;
        margin: 0px 40px 0px auto; 
        @media only screen and (max-width: 425px){
            width: 150px;
            margin: 0 0 0 8px;            
            }
       
    }
    label{
        margin-right: 20px;
        margin-top: 8px;
        font-size: 12px;
        @media only screen and (max-width: 425px){
            margin: 10px 0 0 25px;
            font-size: 14px;
            
        }

    }
    

}