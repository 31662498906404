.titleModal {
  font-size: 30px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.containerSections {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.titleSections {
  font-size: 14px;
  color: black;
  margin-bottom: 30px;
  height: 70px;
}

.containerBtnDowload {
  display: flex;
  justify-content: flex-end;
}

.modal-content {
  background-color: transparent !important;
}

.modal-content {
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
}
