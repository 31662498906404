.containerScroll {
  width: 100%;
  height: 80vh;
  position: relative;
  .containerForm {
    width: 50%;

    @media screen and (max-width: 425px) {
      width: 100%;
    }

    @media only screen and (max-width: 768px) and (min-width: 426px) {
      width: 100%;
      scale: 0.8;
      margin-top: 10%;
    }

    .loginMode {
      @media only screen and (max-width: 768px){
        width: 100%;
        margin: 20px 0;
      }
      width: 100%;
      @media only screen and (max-width: 768px){
      display: flex;
      }
      height: auto;
      margin-top: 12px;
      margin-bottom: 10px;
      background-color: white;
      .linkBtn {
        @media only screen and (max-width: 768px){
          margin-left: 0px;
          }
        margin-left: 50px;
        button {
          @media only screen and (max-width: 768px){
            width: 157px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          width: 200px;
          height: 50px;
          &:hover {
            background-color: #07598c;
          }
        }
      }

      .lbl {
        font-size: 22px;
        font-weight: 600;
        margin-left: 20px;
      }
      .oLabel {
        font-size: 18px;
        margin-left: 20px;
      }
      .active {
        font-size: 18px;
        text-decoration: underline;
        // margin-left: 20px;
        background-color: #027cba;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-weight: 600;
        width: 200px;
        height: 50px;

        .lblForgPass {
          cursor: pointer;
          text-decoration: underline;
        }
        label{
          text-decoration: none;
        }
      }
      .nonActive {
        font-size: 18px;
        // margin-left: 20px;
      }
    }
    
    Form {
      // margin: 10px auto 0px auto;
      @media only screen and (max-width: 768px) and (min-width: 426px) {
        padding: 10px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      // height: 95%;
      // background-color: pink;
      padding: 20px ;
      justify-content: center;
      .logoContainer {
        @media only screen and (max-width: 768px){
          width: 100%;
          margin-top: 30px;
          
          }
        justify-content: center;
        width: 80%;
        align-items: center;
        .headerlbl {
          @media only screen and (max-width: 768px){
           
            margin: 20px 0;
            
            }
          font-size: 28px;
          font-weight: bold;
          margin: 0px auto ;
        }
        .logo {
          margin: 0 auto;
         
          @media only screen and (max-width: 768px){
            width:206px;
            height:62px;
            // margin-right: 13%;
            }
          // margin-right: 55px;
          width: 166;
          height: 50;
          //antes 80px
        }
      }

      .wrongLabelContainer{
        // margin-bottom: 10px;
        align-items: flex-end;
        
        .wrongLabel{
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 0, 0, 0.7);
          margin-left: 10px;
        }
        svg{
          
          margin: 0 10px 2px auto;
        }   

      }
      .inputsContainer{
        @media only screen and (max-width: 768px){
          width: 100%;
          
          }
        width: 90%;
        flex-direction: column;
        align-items: center;
        
      }
      .inputsContainer > div{
        @media only screen and (max-width: 768px){
          width: 100%;
          margin-bottom: 25px;
          
          }
        margin-bottom: 15px;
        width: 90%;
        // border: 4px solid white;
      }

      .lastContainer{
        @media only screen and (max-width: 768px){
          width: 100%;
          
          }
        width: 90%;
        // padding: 12px 12px 19px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
    }

    .formDiv {
      height: 46px;
      width: 574px;
      margin: 20px 0px 40px 0px;

      .form-control {
        display: flex;
        justify-items: center;
        @media only screen and (max-width: 768px){
          width: 56%;
        }
        width: 100%;
        // height: 65px;
        height: 100%;
        font-size: 18px;
        // margin: 20px 0px 0px 0px;
        background-color: #e8f0fe;
      }
    }

    .secretPass {
      height: 46px;
      width: 574px;
      margin: 20px 0px 20px 0px;

      .form-control {
        @media only screen and (max-width: 768px){
          width: 56%;
        }
        width: 100%;
        // height: 65px;
        height: 100%;
        font-size: 18px;
        // margin: 20px 0px 0px 0px;
        background-color: #e8f0fe;
      }
    }
  }
  .containerImg {
    width: 50%;
    // background: red;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      // background: no-repeat fixed center;
      // background-size: cover;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .buttonStyle {
    @media only screen and (max-width: 768px){
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      
      }
    width: 90%;
    height: 56px;
    padding: 12px 12px 19px 12px;
    // height: 12%;
    // margin: 30px 10px 10px 0px;
    font-size: 24px;
    font-weight: 600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #027cba;
    &:hover {
      background-color: #07598c;
    }
  }
  .linkStyle {
    font-size: 18px;
    text-decoration: underline;
    margin-top: 10px;
    color: black;
    .lblForgPass {
      cursor: pointer;
    }
    &:hover {
      color: blue;
    }
  }

  .password-icon {
      position:absolute;
    // top: calc(52vh - 6px);
    // right: calc(50% + 100px);
    // // left: 20px;
    // // bottom: 20px;
    // display: flex;
    // top: 44%;
    // left: 50%;
    @media only screen and (max-width: 768px) and (device-width: 390px) 
    and (device-height: 844px){
      position: absolute;
      left: 23em;
      top: 24em;
      z-index: 1;
    }

    top: calc(59% - 70px);
    left: calc(48% - 120px);

    cursor: pointer;
    width: 25px;
    height: 20px;
  }
}
