.fullContainer {
  width: 100%;
  height: auto;
  background-color: white;
  .headerContainer {
    width: 100%;
    height: 80px;
    background-color: white;
    justify-content: space-between;
     align-items: center;

    label {
      margin-left: 60px;
      font-size: 34px;
      font-weight: bold;
      margin-top: 15px;
    }
    nav {
      justify-content: end;
      margin-right: 60px;
      margin-top: 15px;
    }

    button {
      border: none;
      box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 8px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 5px -1px;
    }
    #leftBtn {
      margin-right: 5px;
    }
    #rightBtn{
        margin-right: 60px;
    }

    .counterNums {
      // border-bottom: 3px solid #027CBB;
      display: inline;
      border-bottom: 3px solid #027cbb;

      // position: relative;
       font-size: 16px;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px;
      // text-decoration: underline;
    }
    .counterNums:nth-of-type(3){
        margin-right: 10px;
    }

    // .countContainer{
    //     margin-left: auto;
    //     .counter{
    //         font-size: 18px;
    //         align-items: center;

    //         button:nth-of-type(1){
    //           margin-left: 0px;
    //         }
    //         button{
    //           width: 40px;
    //           height: 40px;
    //           margin: 5px 10px 0 10px;
    //           align-items: center;
    //           svg{
    //               margin: auto auto;
    //           }

    //         }
    //         .counterNum{
    //             // border-bottom: 3px solid #027CBB;
    //             display: inline;
    //             border-bottom: 3px solid #027CBB;

    //             // position: relative;
    //             // font-size: 16px;
    //              line-height: 20px;
    //             // text-decoration: underline;
    //         }
    //       }
    // }
  }
  .imgRow {
    justify-content: space-between;
    .card:nth-of-type(1) {
      margin-left: 60px;
    }
    .card:nth-of-type(3) {
      margin-right: 60px;
    }

    .card {
      width: 18rem;
      border-radius: 0;
      margin-top: 20px;
      margin-bottom: 15px;
      border: none;
      .card-body {
        text-align: center;
      }
    }
    img {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }
  }
}
