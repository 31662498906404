.mainContainer{
    width: 100%;
    height: auto;
    background-color: white;
    .Container{
        height: 50%;
        background-color: white;
        margin: 40px 200px 40px 200px;
        .headerPagination{
            width: 100%;
            height: 26px;
            background-color: white;
            justify-content: space-between;
            align-items: center;
            label{
                font-size: 24px;
                font-weight: 600;
            }
            button{
                border: none;
                box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 8px -1px, rgba(0, 0, 0, 0.3) 0px 1px 5px -1px;

            }
            #leftBtn{
                margin-right: 5px;
            }
        }
        .imgSlider{
            width: 100%;
            height: calc(100% - 26px);
            margin-top: 20px;
            .leftImg{
                width: 50%;
                height: calc(100% - 26px);
                background-color: white;
                img{
                    width: 98%;
                    height: 100%;
                    object-fit: cover;
                    
                }
                
            }
            .rightImg{
                width: 50%;
                height: calc(100% - 26px);
                background-color: white;
                justify-content: flex-end;
                
                img{
                    width: 98%;
                    height: 100%;
                    object-fit: cover;      
                }
            }
            
        }
    }
    .Container:nth-of-type(2){
        margin-top: 60px;
        img{
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 10px -1px;
            
        }
        .imgSlider{
            width: 100%;
            height: 100%;
            margin-top: 20px;
            .leftImg{
                width: calc(100% / 3);
                height: calc(100% - 26px);
                background-color: white;
                img{
                    width: 96%;
                    height: 100%;
                    object-fit: cover;
                    
                }
                
            }
            .midImg{
                width: calc(100% / 3);
                height: calc(100% - 26px);
                background-color: white;
                justify-content: center;
                
                img{
                    width: 96%;
                    height: 100%;
                    object-fit: cover;
                    
                }
                
            }
            .rightImg{
                width: calc(100% / 3);
                height: calc(100% - 26px);
                background-color: white;
               
                
                img{
                    width: 96%;
                    height: 100%;
                    object-fit: cover;      
                }
            }
            
        }
    }
}