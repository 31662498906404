span{
    font-size: 14px;
    width: 40%;
    padding: 2px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 20px;
}
.lblBack{
    margin: 15px auto;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }