.MuiBox-root {
  height: 70%;

  .closeModal {
    display: flex;
    justify-content: flex-end;
    button {
      border-color: white;
      background-color: white;
      box-shadow: none;
      border: 2px solid white;
      font-size: 20px;
      transition-duration: 0.2s;
    }
  }
  .modalSize {
    overflow-y: auto;
    height: 90%;
    @media only screen and (max-width: 768px) {
      overflow-y: auto;
      height: 85%;
    }
  }
  .title {
    margin-top: 5px;
  }
  .uploadImage {
    width: 100%;
    height: 400px;
    flex-direction: column;
    .imgContainer {
      height: 90%;
      margin-bottom: 10px;
      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        background-repeat: no-repeat;
      }
    }
    .buttonsUploadContainer {
      margin-top: auto;
      height: 10%;
      input {
        margin-top: auto;
        margin-bottom: auto;
      }
      button {
        margin-left: auto;
      }
    }
  }
}

.uploadIconButton {
  color: #007bff !important;
  z-index: 0;
}

.modalMrkt {
  .closeModal {
    position: absolute;
    top: 1%;
    right: 2%;
  }
  .img-rendered {
    background-color: #bfd4e4;
    border-radius: 1em;
    position: relative;
    cursor: pointer;
    padding: 2.5em 0;
    margin-bottom: 1em;
    text-align: center;
    .img-default {
      width: 50%;
      margin-bottom: 1em;
    }
    h2 {
      color: #2b495f;
      text-align: center;
      text-decoration: underline;
      font-size: 30px;
    }
    .img-temp {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: #fff;
      border: 1px solid #e4e4e4;
      border-radius: 1em;
    }

    @media (min-width: 1280px) {
      height: 100%;
      padding: 0;
      margin-bottom: 0;
      .img-default {
        height: auto;
        position: absolute;
        left: 50%;
        top: 10%;
        transform: translate(-50%);
      }
      h2 {
        position: absolute;
        bottom: 10%;
        width: 100%;
      }
    }
  }
  .title {
    h2 {
      font-size: 25px;
    }
  }
  .numering {
    position: relative;
    .dolar-char {
      position: absolute;
      top: 0px;
      font-size: 25px;
      width: auto;
      margin: 0;
    }
    input {
      font-size: 22px;
      width: 100%;
      border: 1px solid #ccc;
      padding-left: 1em;
    }
    .buttons-counter {
      font-size: 25px;
      text-align: center;
      padding: 0 10px;
      .boton {
        background-color: #d9d9d9;
        cursor: pointer;
        width: 100%;
        border: none;
      }
    }
  }
  .boton-confirmar {
    width: 100%;
    font-weight: bold;
    color: #fff;
    font-size: 25px;
    background-color: #027cbb;
    border: none;
    padding: 10px 0;
  }
  .boton-confirmar.disabled {
    color: #ffffffa1;
    background-color: #027cbba6;
  }
}
.tableMrkt {
  thead {
    th {
      text-align: center;
      vertical-align: middle;
    }
  }
  tbody {
    td {
      text-align: center;
    }
  }
}
