.modalBackground {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  // background-color: rgb(0, 0, 0); /* Fallback color */
  // background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  background-color: rgba(0, 0, 0, 0.1);

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}
.error {
  color: red;
  font-size: 12px;
  @media screen and (max-width: 375px) {
    height: 20px;

    position: relative;
    bottom: 40px;
  }
}
.displayNone {
  display: none;
}
.bold {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 40px;
}

.messageButtonSend {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}
.iCon {
  position: absolute;
  right: 52%;
  top: 36%;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
}
.modalContainer {
  width: 809px;
  height: 450px;

  background-color: white;

  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0;

  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
  input {
    height: 32px;
    width: 216px;
    margin-bottom: 15px;
    @media screen and (max-width: 375px) {
      width: 216px;
      height: 32px;

      position: relative;
      bottom: 30px;
      left: 60px;
    }
  }
}

.modalContainer .title {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: 600;
}

.formTitle {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.modalContainer .body {
  width: 100%;
  height: 80%;
  justify-content: center;
  font-size: 1rem;
  background-color: white;
  .leftContainer {
    width: 60%;
    background-color: white;
    .productDetail {
      h5 {
        margin-left: 10px;
        margin-top: 10px;
      }
      label {
        margin-left: 10px;
        font-size: 14px;
      }
      #unitPrice {
        margin-left: 40px;
      }
      .counter {
        font-size: 18px;
        margin-left: 0;
        margin-top: 50px;
        button:nth-of-type(1) {
          margin-left: 10px;
        }
        button {
          width: 40px;
          height: 40px;
          margin: 5px 20px 0 30px;
        }
        #money {
          margin-left: 30px;
        }
      }
    }
    .imgContainer {
      width: 150px;
      height: 150px;
      background-color: white;
      margin-top: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .rightContainer {
    width: 40%;
    height: 100%;
    background-color: white;
    label {
      margin-top: 5px;
      font-weight: 500;
      color: gray;
    }
    .price {
      margin-top: 60px;
      font-size: 18px;
      label {
        color: black;
      }

      label:nth-of-type(2) {
        margin-left: 40px;
        color: black;
      }
    }
    .payButtonBottom {
      width: 300px;
      height: 50px;
      border-radius: 0%;
      background-color: #027cba;
      color: white;
      font-size: 22px;
      font-weight: 600;
      margin-top: 70px;
      &:hover {
        background-color: #03487d;
        color: white;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;

    label {
      font-size: 14px;
      width: 30%;
    }
  }
  @media screen and (max-width: 375px) {
    label {
      position: relative;
      right: 130px;
      justify-self: flex-start;
    }
  }
}

.sendButtonBottom {
  display: inline;
  width: 307px;
  height: 56px;
  border-radius: 0%;
  background-color: #027cba;
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-top: 70px;
  &:hover {
    background-color: #03487d;
    color: white;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
}
.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
