@media only screen and (max-width: 420px) {
	.menu-area {
		display: none !important;
	}
	.tableList {
		display: none !important;
	}

	.mainContainer {
		.topSection {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			* {
				font-size: 0.9rem !important;
				margin: 0 !important;
			}
			.payButton {
				border-radius: 150px !important;
			}
			.table {
				width: 100%;
				text-align: center;
				font-size: 1.1rem !important;
			}
		}
		.tableList {
			* {
				word-wrap: break-word;
				font-size: 0.8rem;
			}
		}
		.infoSection {
			flex-direction: row !important;
			width: 100%;
			align-items: center;
			.delete {
				font-size: 0.8rem;
			}
		}
		.specProduct {
			h4 {
				font-size: 0.9rem;
			}
			width: 100% !important;
		}

		.drop {
			display: none !important;
		}
	}

	.containerProducts {
		display: flex;
		flex-direction: row !important;
		height: auto !important;
		border: none !important;
		.valueContainer {
			font-size: 0.6rem;
		}
		.imageCheck {
			width: 50% !important;
		}
		.secondContainer {
			margin-left: 5%;
			flex-direction: column;

			width: 60% !important;
		}

		.totalPrice {
			label {
				font-size: 0.8rem !important;
			}
		}
		.quantityContainer {
			width: auto !important;
		}
		.countContainer {
			width: auto !important;
			.counter {
				button {
					width: 6.5vw !important;
					height: 6.5vw !important;
				}
				label {
					font-size: 0.8rem;
				}
			}
			.exists {
				font-size: 0.7rem;
			}
			font-size: 1rem;
		}
	}
}

@media only screen and (min-width: 421px) and (max-width: 768px) {
	h4 {
		font-size: 0.9rem !important;
	}
	.secondContainer {
		* {
			margin-bottom: 0;
		}
		.delete {
			margin-top: 3% !important;
		}
	}
}
